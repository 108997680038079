<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import { merge, uniqueId } from "lodash";
import { BaseAtom, getContentManager, getModeManager, getSEOManager, } from "components/cards/atoms";
import { ImageParticle, TextParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { getPublishDuration } from "components/cards/utilities";
import { t } from "js/i18n";
import { JanusSEOPriority } from "js/seo";
import { onDestroy } from "svelte";
export let container = undefined;
export let slug = undefined;
export let link = undefined;
export let imageUrl = undefined;
export let publishDateTime;
export let viewTimeDuration;
export let author = undefined;
export let showLocationText = undefined;
export let locationText = undefined;
export let imgUserIcon;
export let publishDateAndDurationText = undefined;
export let txtAuthorInfo = undefined;
export let txtSecondAuthor = undefined;
export let txtShareText = undefined;
export let titleText = undefined;
export let showUserIconIn = undefined;
export let showAuthorIn = undefined;
export let showPublishDateAndDuration = undefined;
export let showSecondAuthorInfo = undefined;
export let showShareStrip = undefined;
export let showTwitter = undefined;
export let showFacebook = undefined;
export let showLine = undefined;
export let showWeibo = undefined;
export let showShareLink = undefined;
export let showShareMail = undefined;
export let imgTwitter = undefined;
export let imgShareIcon = undefined;
export let imgFacebook = undefined;
export let imgLine = undefined;
export let imgWeibo = undefined;
export let infoItemsGap;
export let setPageMetadata = undefined;
$: mtc = getContentManager().getMediaTypeConfigurationStore("article", slug, link);
$: shareLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.shareLinkUrl) === null || _a === void 0 ? void 0 : _a.href;
//$: console.log('ArticleHeaderB', imageUrl, titleText?.text, setPageMetadata);
let atomWidthPx;
const onShareLink = () => {
    var _a;
    let rawUrl = new URL(shareLink, window.origin).toString();
    let url = encodeURIComponent(rawUrl);
    let title = encodeURIComponent(titleText === null || titleText === void 0 ? void 0 : titleText.text);
    (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(rawUrl);
    alert("URL copied to clipboard.");
};
const onShareMail = () => {
    let rawUrl = new URL(shareLink, window.origin).toString();
    let url = encodeURIComponent(rawUrl);
    let title = encodeURIComponent(titleText === null || titleText === void 0 ? void 0 : titleText.text);
    url = `mailto:someone@domain.com?subject=${title}&body=${url}`;
    window.open(url, "_self");
};
let socialMediaIcons = CONFIG === null || CONFIG === void 0 ? void 0 : CONFIG.socialMediaIcons;
$: viewTimeDurationDisplay = getPublishDuration(publishDateTime, $t);
$: socialURL = new URL(shareLink, window.location.href);
$: shareUrlFB = `https://www.facebook.com/sharer/sharer.php?u=${socialURL === null || socialURL === void 0 ? void 0 : socialURL.href};src=sdkpreparse`;
$: shareUrlTwitter = `https://x.com/intent/post?text=${titleText === null || titleText === void 0 ? void 0 : titleText.text}+${socialURL === null || socialURL === void 0 ? void 0 : socialURL.href}`;
$: shareUrlLine = `https://social-plugins.line.me/lineit/share?url=${socialURL === null || socialURL === void 0 ? void 0 : socialURL.href}&text=${titleText === null || titleText === void 0 ? void 0 : titleText.text}`;
$: shareUrlWeibo = `https://service.weibo.com/share/share.php?url=${encodeURIComponent(socialURL === null || socialURL === void 0 ? void 0 : socialURL.href)}`;
const mode = getModeManager();
$: langStore = mode.getCurrentLanguageStore();
$: langValue = ($langStore || "").toLowerCase();
const seoManager = getSEOManager();
const seoConfigKey = uniqueId();
$: if (setPageMetadata && (titleText === null || titleText === void 0 ? void 0 : titleText.text)) {
    seoManager.registerSEOConfiguration(Object.assign({ key: seoConfigKey, priority: JanusSEOPriority.Card, isPopup: false, title: titleText.text }, (imageUrl && { imageUrl })));
}
onDestroy(() => {
    seoManager.unregisterSEOConfiguration(seoConfigKey);
});
</script>

<BaseAtom
    _isotope="ArticleHeaderBAtom"
    _cssVariablePrefix="article-b"
    {...container}
    {infoItemsGap}
    bind:_clientWidthPx={atomWidthPx}
>
    <div
        class="article-header-atom"
        class:mobile-width={atomWidthPx <= 450}
        class:tablet-width={atomWidthPx > 450 && atomWidthPx <= 830}
    >
        <div class="icon-author">
            <div class="user-icon">
                {#if showUserIconIn}
                    <ImageParticle {...author.image} _block={true} />
                {/if}
            </div>
            {#if author?.nameText?.text}
                <div class="author-info">
                    {#if showAuthorIn}
                        <TextParticle
                            {...author?.nameText}
                            text={`by ${author?.nameText?.text}`}
                            _block={true}
                        />
                    {/if}
                    {#if showSecondAuthorInfo && author?.positionText?.text}
                        <TextParticle {...author?.positionText} _block={true} />
                    {/if}
                    {#if showPublishDateAndDuration && viewTimeDurationDisplay}
                        <div class="publish-duration-text">
                            <span>{$t('Published')}</span>
                            <TextParticle
                                {...publishDateAndDurationText}
                                text={`${viewTimeDurationDisplay}`}
                                _block={true}
                            />
                        </div>
                    {/if}
                    {#if showLocationText && locationText?.text}
                        <TextParticle {...locationText} _block={true} text={`from ${locationText?.text}`} />
                    {/if}
                </div>
            {/if}
        </div>
        <div class="share-strip">
            {#if showShareStrip}
                <div class="share-text">
                    <TextParticle {...txtShareText} />
                </div>
                <div class="share-logos">
                    <div class="copy-icon">
                        {#if showShareLink}
                            <ButtonParticle
                                on:click={onShareLink}
                                {...merge({}, imgShareIcon, {
                                    image: {
                                        // icon: "yc_icon_link",
                                        // iconColor: "#164194",
                                        // container: {
                                        //     width: "2.25rem",
                                        //     height: "2.25rem",
                                        // },
                                        source: socialMediaIcons?.copyLink,
                                    },
                                })}
                            />
                        {/if}
                    </div>
                    <div class="mail-icon">
                        {#if showShareMail}
                            <ButtonParticle
                                on:click={onShareMail}
                                {...merge({}, imgShareIcon, {
                                    image: {
                                        // icon: "yc_icon_email",
                                        // iconColor: "#164194",
                                        // container: {
                                        //     width: "2.25rem",
                                        //     height: "2.25rem",
                                        // },
                                        source: socialMediaIcons?.mail,
                                    },
                                })}
                            />
                        {/if}
                    </div>
                    <div class="twitter-icon">
                        {#if showTwitter}
                            <ButtonParticle
                                {...imgShareIcon}
                                image={{
                                    ...imgShareIcon?.image,
                                    source: socialMediaIcons?.x,
                                }}
                                link={shareUrlTwitter}
                            />
                        {/if}
                    </div>
                    <div class="facebook-icon">
                        {#if showFacebook}
                            <ButtonParticle
                                {...imgShareIcon}
                                image={{
                                    ...imgShareIcon?.image,
                                    source: socialMediaIcons?.facebook,
                                }}
                                link={shareUrlFB}
                            />
                        {/if}
                    </div>
                    {#if showWeibo && langValue !== "th"}
                        <div class="weibo-icon">
                            <ButtonParticle
                                {...imgShareIcon}
                                image={{
                                    ...imgShareIcon?.image,
                                    source: socialMediaIcons?.weibo,
                                }}
                                link={shareUrlWeibo}
                            />
                        </div>
                    {/if}
                    {#if showLine && langValue === "th"}
                        <div class="line-icon">
                            <ButtonParticle
                                {...imgShareIcon}
                                image={{
                                    ...imgShareIcon?.image,
                                    source: socialMediaIcons?.line,
                                }}
                                link={shareUrlLine}
                            />
                        </div>
                    {/if}
                </div>
            {/if}
        </div>
    </div>
</BaseAtom>

<style>
    .copy-icon,
    .mail-icon,
    .twitter-icon,
    .line-icon,
    .weibo-icon {
        margin: 0 0.5rem;
    }

    .facebook-icon {
        margin: 0 0.25rem;
    }
    .article-header-atom {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        justify-items: flex-start;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .article-header-atom.tablet-width {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }
    .article-header-atom.mobile-width .share-strip {
            justify-self: flex-start;
        }
    .author-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: var(--article-b-info-items-gap, 0.25rem);
    }
    .author-info > :global(*) {
        justify-content: left !important;
    }
    .share-strip {
        justify-self: end;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 0.5rem;
    }
    .share-text {
        margin-right: 1rem;
    }
    .share-logos {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        /* gap: 0.75rem; */
    }
    .icon-author {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .publish-duration-text{
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }
    .publish-duration-text span{
            font-size: 1rem;
            font-family: Fira Sans;
            text-wrap: nowrap;
        }</style>
