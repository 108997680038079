<script lang="ts" context="module">import { register } from 'swiper11/swiper-element-bundle.mjs';
register();
</script>

<script lang="ts">import "components/cards/particles/YinzCam";
import ImageParticle from "components/cards/particles/YinzCam/Core/Image/ImageParticle.svelte";
import TextParticle from "components/cards/particles/YinzCam/Core/Text/TextParticle.svelte";
import ButtonParticle from "components/cards/particles/YinzCam/Core/Button/ButtonParticle.svelte";
import { BaseParticle } from "components/cards/particles";
import { onMount } from 'svelte';
export let container = undefined;
export let carouselPositionText = undefined;
export let photoTitleText = undefined;
export let photoDescriptionText = undefined;
export let currentPhoto = undefined;
export let carouselPhoto = undefined;
export let photos = [];
export let loopCarousel = true;
//export let centerCarousel: boolean = false;
let curIndex = null;
let curPhotoUrl = "";
let curPhotoTitle = "";
let curPhotoDesc = "";
let curPhotoStatusText = "";
let bottomSwiper;
let particleWidthPx = null;
$: isMobile = particleWidthPx <= 600;
let swiperInit = false;
$: if (!swiperInit && mounted && bottomSwiper && (photos === null || photos === void 0 ? void 0 : photos.length) > 0) {
    swiperInit = true;
    bottomSwiper.initialize();
}
function onSwiperInit(swiper) {
    //swiperInit = true;
    //setTimeout(() => swiper.slideTo(0, 0), 1000);
    const url = new URL(location.href);
    const currentPhotoUrl = url.searchParams.get("currentPhotoUrl");
    const currentPhotoIndex = Math.max((photos === null || photos === void 0 ? void 0 : photos.findIndex((e) => e.source === currentPhotoUrl)) || 0, 0);
    /*
    if (loopCarousel) {
      swiper.slideToLoop(currentPhotoIndex, 0);
    } else {
      swiper.slideTo(currentPhotoIndex, 0);
    }
    */
    curIndex = currentPhotoIndex;
}
function onSlideChange(e) {
    const index = e.detail[0].realIndex;
    curIndex = index;
}
function updatePhotoInfo(index) {
    const photo = photos === null || photos === void 0 ? void 0 : photos[index];
    curPhotoUrl = (photo === null || photo === void 0 ? void 0 : photo.source) || "";
    curPhotoTitle = (photo === null || photo === void 0 ? void 0 : photo.title) || "";
    curPhotoDesc = (photo === null || photo === void 0 ? void 0 : photo.description) || "";
    curPhotoStatusText = `Photo ${index + 1}/${photos === null || photos === void 0 ? void 0 : photos.length}`;
}
$: updatePhotoInfo(curIndex);
function onLeftClick() {
    let sw = bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper;
    if (sw && photos.length > 0) {
        if (loopCarousel) {
            sw.slidePrev(400);
        }
        else {
            if (sw.isBeginning) {
                sw.slideTo(photos.length - 1, 400);
            }
            else {
                sw.slidePrev(400);
            }
        }
        if (curIndex <= 0 || curIndex >= photos.length) {
            curIndex = photos.length - 1;
        }
        else {
            curIndex = curIndex - 1;
        }
    }
}
function onRightClick() {
    let sw = bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper;
    if (sw && photos.length > 0) {
        if (loopCarousel) {
            sw.slideNext(400);
        }
        else {
            if (sw.isEnd) {
                sw.slideTo(0, 400);
            }
            else {
                sw.slideNext(400);
            }
        }
        if (curIndex < 0 || curIndex >= photos.length - 1) {
            curIndex = 0;
        }
        else {
            curIndex = curIndex + 1;
        }
    }
}
let mounted = true;
onMount(() => {
    bottomSwiper.on = {
        afterInit: onSwiperInit
    };
    mounted = true;
});
</script>

<BaseParticle _name="GalleryCarouselParticle" _cssVariablePrefix="gallery-particle" bind:_clientWidthPx={particleWidthPx} {...container}>
  <div class="photo-viewer" class:mobile-layout={isMobile}>
    <div class="selected-image-row">
      <div class="left-arrow">
        <ButtonParticle
          on:click={onLeftClick}
          container={{
            background: { visible: true, color: "#B3B3B3" },
            border: { visible: true, radius: "50%" },
          }}
          image={{
            icon: "yc_icon_chevron_left",
            container: { width: "2.5rem", height: "2.5rem" },
            fillHeight: true,
            fillWidth: true,
          }}
        />
      </div>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="selected-image">
        <ImageParticle _block={true} {...currentPhoto} source={curPhotoUrl} />
      </div>
      <div class="right-arrow">
        <ButtonParticle
          on:click={onRightClick}
          container={{
            background: { visible: true, color: "#B3B3B3" },
            border: { visible: true, radius: "50%" },
          }}
          image={{
            icon: "yc_icon_chevron_right",
            container: { width: "2.5rem", height: "2.5rem" },
            fillHeight: true,
            fillWidth: true,
          }}
        />
      </div>
    </div>
    <div class="photo-info-row">
      <div class="photo-status">
        <TextParticle {...carouselPositionText} text={curPhotoStatusText} />
      </div>
      <div class="photo-title-description">
        <div class="photo-title">
          <TextParticle {...photoTitleText} text={curPhotoTitle} />
        </div>
        <div class="photo-description">
          <TextParticle {...photoDescriptionText} text={curPhotoDesc} />
        </div>
      </div>
      <div class="photo-share-links">
      </div>
    </div>
    <div class="bottom-swiper-wrapper">
      <swiper-container
        class="bottom-slider"
        bind:this={bottomSwiper}
        init={false}
        simulate-touch={false}
        slides-per-view={5}
        slides-per-group={1}
        space-between={5}
        loop={loopCarousel}
      >
      {#if photos?.length > 0}
      {#each Array(Math.ceil(photos.length/5)) as __}
      {#each photos as photo, i}
        <swiper-slide>
          <ImageParticle
            _block={true}
            {...carouselPhoto}
            source={photo.source}
            alt={`Photo ${i + 1}`}
          />
        </swiper-slide>
      {/each}
      {/each}
      {/if}
      </swiper-container>
    </div>
  </div>
</BaseParticle>

<style>
  /*
        simulate-touch={true}
        on:swiperrealindexchange={onSlideChange}
        pagination={false}
        navigation={false}
        speed={400}
        slides-per-view={'auto'}
        slides-per-group={1}
        space-between={12}
        observer={true}
        observe-slide-children={true}
        slide-to-clicked-slide={true}
        centered-slides={centerCarousel && !loopCarousel}
        center-insufficient-slides={centerCarousel && !loopCarousel}
        grab-cursor={true}
  */
  .photo-viewer {
    width: 100%;
  }

  .photo-viewer > .selected-image-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .photo-viewer.mobile-layout > .selected-image-row {
    gap: 0.5rem;
  }

  .photo-viewer > .selected-image-row > .left-arrow, .photo-viewer > .selected-image-row > .right-arrow {
    flex: 0;
  }

  .photo-viewer > .selected-image-row > .selected-image {
    flex: 1;
    height: 100%;
  }

  .photo-viewer > .photo-info-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 1.75rem 4.65rem;
  }

  .photo-viewer.mobile-layout > .photo-info-row {
    padding: 0.5rem 2.25rem;
  }

  .photo-viewer > .photo-info-row > .photo-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .photo-viewer > .photo-info-row > .photo-title-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .photo-viewer > .photo-info-row > .photo-share-links {
    margin-left: auto;
  }

  .photo-viewer > .photo-share-links {
    display: flex;
    justify-content: flex-start;
    padding: 1rem 0;
  }

  .photo-viewer > .bottom-swiper-wrapper {
    padding: 0 5.5rem;
  }

  .photo-viewer.mobile-layout > .bottom-swiper-wrapper {
    display: none;
  }

  .photo-viewer > .bottom-swiper-wrapper :global(swiper-slide) {
    /*flex: 1 1 auto;*/
    width: auto;
    max-width: 20%;
  }</style>
