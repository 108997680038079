<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { onMount } from "svelte";
import { t } from "js/i18n";
import "lodash";
import { DateTime } from "luxon";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
import { resolveUrl } from "js/url";
import { formatDateTimeParts } from "components/cards/utilities";
export let container;
export let imgLeaguelogo;
export let imgOpponentLogo;
export let txtNextMatch;
export let txtMatchDate;
export let txtOpponentTricode;
export let txtHomeOrAwayCode;
export let optionHomeOrAway;
export let matchDateTime;
export let txtCountDownTime;
export let txtCountDownText;
export let countdownBoxColor;
export let symbolColor;
export let symbolFontFamily;
export let matchCentreUrl;
let atomWidthRem;
const formatDateTime = (dateString) => {
    return formatDateTimeParts(dateString, {
        role: 'fixtures',
        blankArrayItemsIfInvalid: true,
    }, {});
};
const computeCountdown = (dateString) => {
    var _a;
    let matchDate = DateTime.fromISO(dateString);
    let values = (_a = matchDate.diff(DateTime.now(), [
        "days",
        "hours",
        "minutes",
        "seconds",
    ])) === null || _a === void 0 ? void 0 : _a.values;
    [ctDays, ctHours, ctMins, ctSeconds] = [
        (values === null || values === void 0 ? void 0 : values.days) > 0 ? values === null || values === void 0 ? void 0 : values.days : 0,
        (values === null || values === void 0 ? void 0 : values.hours) > 0 ? values === null || values === void 0 ? void 0 : values.hours : 0,
        (values === null || values === void 0 ? void 0 : values.minutes) > 0 ? values === null || values === void 0 ? void 0 : values.minutes : 0,
        (values === null || values === void 0 ? void 0 : values.seconds) > 0 ? values === null || values === void 0 ? void 0 : values.seconds : 0,
    ];
};
$: isMobile = atomWidthRem <= 44;
$: isTablet = atomWidthRem <= 60 && atomWidthRem > 44;
$: homeOrAway = optionHomeOrAway === "home" ? "(H)" : "(A)";
$: [day, date, month, year, , time, timezone] =
    formatDateTime(matchDateTime);
$: ctSeconds = "0";
$: ctDays = "0";
$: ctHours = "0";
$: ctMins = "0";
$: isFutureMatch = DateTime.fromISO(matchDateTime) > DateTime.now();
$: isFutureMatch && computeCountdown(matchDateTime);
$: countDownID = setInterval(() => isFutureMatch && computeCountdown(matchDateTime), 1000);
onMount(() => {
    return () => clearInterval(countDownID);
});
</script>

<BaseAtom
    _isotope="NextMatchBAtom"
    _cssVariablePrefix="next-match"
    {...container}
    {countdownBoxColor}
    {symbolColor}
    {symbolFontFamily}
    bind:_clientWidthRem={atomWidthRem}
    _link={$t(resolveUrl(matchCentreUrl).href)}
>
    <div
        class="next-match-container"
        class:layout-tablet-width={isTablet}
        class:layout-mobile-width={isMobile}
        class:display-none={!matchDateTime}
    >
        <div class="match-box">
            <div class="competition-logo">
                <ImageParticle {...imgLeaguelogo} _block={true} />
            </div>
            <div class="next-match-date">
                <TextParticle {...txtNextMatch} />
                <TextParticle
                    {...txtMatchDate}
                    text={`${isMobile ? $t(day?.slice(0, 3)) : $t(day)} ${date} ${isMobile ? $t(month?.slice(0, 3)) : $t(month)}`}
                />
            </div>
            <div class="opponent-details">
                <div class="opponent-logo">
                    <ImageParticle {...imgOpponentLogo} _block={true} />
                </div>
                <div class="opponent-tricode">
                    <TextParticle {...txtOpponentTricode} />
                </div>
                <div class="home-or-away">
                    <TextParticle {...txtHomeOrAwayCode} text={homeOrAway} />
                </div>
                <div class="symbol-gt">
                    <span>&gt;</span>
                </div>
            </div>
        </div>
        {#if !isMobile}
            <div class="countdown-box">
                <div class="days">
                    <TextParticle
                        {...txtCountDownTime}
                        text={`${ctDays?.toString()?.padStart(2, "0")}`}
                    />
                    <!-- A space is added to 'D' to prevent Thai conversion. TO BE KEPT until we get translation -->
                    <TextParticle {...txtCountDownText} text={$t("D ")} />
                </div>
                <div class="hours"> 
                    <TextParticle
                        {...txtCountDownTime}
                        text={`${ctHours?.toString()?.padStart(2, "0")}`}
                    />
                    <TextParticle {...txtCountDownText} text={$t("H")} />
                </div>
                <div class="mins">
                    <TextParticle
                        {...txtCountDownTime}
                        text={`${parseInt(ctMins).toFixed(0)?.toString()?.padStart(2, "0")}`}
                    />
                    <TextParticle {...txtCountDownText} text={$t("M")} />
                </div>
                <div class="seconds">
                    <TextParticle
                        {...txtCountDownTime}
                        text={`${parseInt(ctSeconds).toFixed(0)?.toString()?.padStart(2, "0")}`}
                    />
                    <TextParticle {...txtCountDownText} text={$t("S")} />
                </div>
            </div>
        {/if}
    </div>
</BaseAtom>

<style>
    .next-match-container {
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: 3fr 1fr;
        align-items: center;
    }

    .next-match-container.display-none {
        display: none !important;
    }

    .competition-logo {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .next-match-container.layout-mobile-width {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .next-match-container.layout-mobile-width .countdown-box {
            gap: 0.25rem;
        }
    .match-box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;
        gap: 0.5rem;
    }
    .next-match-date {
        display: flex;
        flex: 0 0 max-content;
        gap: 0.25rem;
    }
    .symbol-gt {
        font-size: 1.5rem;
        font-weight: 700;
        font-family: var(--next-match-symbol-font-family, inherit);
        color: var(--next-match-symbol-color, #164194);
        margin-top: calc(14px - 1rem);
    }
    .opponent-details {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 0.25rem;
    }
    .countdown-box {
        display: grid;
        grid-template-columns: 2.5rem 2.5rem 2.5rem 2.5rem;
        justify-items: center;
        /* gap: 0.5rem; */
    }
    .countdown-box .seconds,
        .countdown-box .days,
        .countdown-box .hours,
        .countdown-box .mins {
            display: flex;
            align-items: flex-end;
            gap: 0.1rem;
        }</style>
