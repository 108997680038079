<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { formatDateTimeRelative } from "components/cards/utilities";
import _, { isEqual, merge } from "lodash";
import { ButtonParticle, TextParticle, DropDownParticle, } from "components/cards/particles/YinzCam";
import { BaseAtom, getMergedParams, } from "components/cards/atoms";
import Axios from "axios";
import RichTextParticle from "components/cards/particles/YinzCam/Core/RichText/RichTextParticle.svelte";
import { resolveUrl } from "js/url";
import { t } from "js/i18n";
export let container;
export let feedUrl = undefined;
export let itemBoxPadding = undefined;
export let overlayPadding = undefined;
export let topLeftButton;
export let topRightButton;
export let titleText;
export let descriptionText;
export let ctaButton;
export let footerBackgroundColor;
export let footerText;
export let footerButton;
export let dropdownProps;
export let shouldAddBoxBorder;
export let boxBorderColor;
export let contentAvailable = false;
let atomWidthRem;
let dataPromise = null;
let postings = null;
let filterLocation = [];
let filterJobType = [];
let filterIndustry = [];
let filterSalary = [];
let isLoading = true;
const mergedParams = getMergedParams();
const checkIfObjectExists = (arr, obj) => {
    return arr.some((ele) => isEqual(ele, obj));
};
const insertFilterOption = (arr, item, filterType) => {
    !checkIfObjectExists(arr, {
        option: item[filterType],
        value: item[filterType],
    }) &&
        arr.push({
            option: item[filterType],
            value: item[filterType],
        });
};
async function fetchData(feedUrl) {
    feedUrl = 'https://2rmlgp4eevh3b59q6iohu8k3pfys4nmi.reach-ats.com/get/job/listing/-/-/-/-/JSON';
    if (feedUrl) {
        const rsp = await Axios.get(feedUrl);
        const items = rsp.data;
        postings = await Promise.all(items.map(async (item, idx) => {
            var _a, _b;
            try {
                const jobId = item['id'];
                if (!jobId) {
                    console.error(`job does not have id!`, item);
                    return;
                }
                let advertText = (item === null || item === void 0 ? void 0 : item["shortdescription"]) || '';
                try {
                    let advertRsp = await Axios.get(`https://2rmlgp4eevh3b59q6iohu8k3pfys4nmi.reach-ats.com/get/job/advert/${item['id']}/JSON`);
                    advertText = (_b = (_a = advertRsp === null || advertRsp === void 0 ? void 0 : advertRsp.data) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b["advertText"];
                }
                catch (_c) {
                    console.error(`unable to get advert text for job id ${item['id']}`);
                }
                insertFilterOption(filterLocation, item, "location");
                insertFilterOption(filterJobType, item, "type");
                insertFilterOption(filterIndustry, item, "group");
                insertFilterOption(filterSalary, item, "salarydescription");
                return {
                    title: item["title"],
                    link: `https://${item["applyurl"]}`,
                    type: item["type"],
                    industry: item["group"],
                    description: advertText,
                    salary: item["salarydescription"],
                    location: item["location"],
                    industries: item["group"],
                    livedate: formatDateTimeRelative(new Date(parseInt(item["activation_timestamp"]) * 1000).toISOString()),
                };
            }
            catch (_d) {
                console.error('Failed to process job item', idx, item);
            }
        }));
        filterIndustry = [...new Set(filterIndustry)];
        filterLocation = [...new Set(filterLocation)];
        filterJobType = [...new Set(filterJobType)];
        filterSalary = [...new Set(filterSalary)];
        setTimeout(() => {
            isLoading = false;
            contentAvailable = true;
        }, 500);
    }
}
$: {
    if (feedUrl) {
        fetchData(feedUrl);
    }
}
let lockedHeightPx = 0;
let wrapperEl;
$: isMobile = atomWidthRem <= 41;
$: isTablet = atomWidthRem <= 54 && atomWidthRem > 41;
$: titleCharacters = isMobile ? 10 : isTablet ? 40 : 50;
$: filteredPostings = (function ($mergedParams) {
    // console.log("filtered posting called", {lockedHeightPx, isLoading});
    if (lockedHeightPx && isLoading) {
        // console.log("NO PENDING LOADS/CHANGES, UNLOCK HEIGHT", lockedHeightPx);
        setTimeout(() => (lockedHeightPx = 0), 250);
    }
    else if (!lockedHeightPx && isLoading) {
        if (wrapperEl) {
            // console.log("PENDING LOADS/CHANGES, LOCK HEIGHT", lockedHeightPx);
            lockedHeightPx = wrapperEl.getBoundingClientRect().height;
        }
    }
    setTimeout(() => (isLoading = false), 500);
    return postings === null || postings === void 0 ? void 0 : postings.filter((posting) => {
        isLoading = true;
        let filIndustry = true;
        let filLocation = true;
        let filType = true;
        let filSalary = true;
        if ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterIndustry) {
            filIndustry = (posting === null || posting === void 0 ? void 0 : posting.industry) === ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterIndustry);
        }
        if ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterLocation) {
            filLocation = (posting === null || posting === void 0 ? void 0 : posting.location) === ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterLocation);
        }
        if ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterJobType) {
            filType = (posting === null || posting === void 0 ? void 0 : posting.type) === ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterJobType);
        }
        if ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterSalary) {
            filSalary = (posting === null || posting === void 0 ? void 0 : posting.salary) === ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterSalary);
        }
        isLoading = false;
        return filIndustry && filLocation && filType && filSalary;
    });
})($mergedParams);
</script>

<BaseAtom
  bind:_clientWidthRem={atomWidthRem}
  _isotope="BlueOctopusCareersAtom"
  _cssVariablePrefix="boca"
  {...container}
  {overlayPadding}
  {itemBoxPadding}
  {footerBackgroundColor}
  {boxBorderColor}
  {lockedHeightPx}
>
  <div
    class="outer-container"
    bind:this={wrapperEl}
  >
    <div class="left-panel"></div>
    <div class="right-panel min-height-box">
      <div
        class="filter-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
      >
        {#if filterIndustry?.length > 0}
          <div class="dropdown-item">
            <DropDownParticle
              {...merge({}, dropdownProps, {
                txtOptions: filterIndustry,
                isResultsYears: false,
                txtListTitle: { text: "Department" },
                paramsName: "filterIndustry",
                id: "filterIndustry",
                titleCharacters,
              })}
            />
          </div>
        {/if}
        {#if filterJobType?.length > 0}
          <div class="dropdown-item">
            <DropDownParticle
              {...merge({}, dropdownProps, {
                txtOptions: filterJobType,
                isResultsYears: false,
                txtListTitle: { text: "Job Type" },
                paramsName: "filterJobType",
                id: "filterJobType",
                titleCharacters,
              })}
            />
          </div>
        {/if}
        {#if filterLocation?.length > 0}
          <div class="dropdown-item">
            <DropDownParticle
              {...merge({}, dropdownProps, {
                txtOptions: filterLocation,
                isResultsYears: false,
                txtListTitle: { text: "Location" },
                paramsName: "filterLocation",
                id: "filterLocation",
                titleCharacters,
              })}
            />
          </div>
        {/if}
        {#if filterSalary?.length > 0}
          <div class="dropdown-item">
            <DropDownParticle
              {...merge({}, dropdownProps, {
                txtOptions: filterSalary,
                isResultsYears: false,
                txtListTitle: { text: "Salary" },
                paramsName: "filterSalary",
                id: "filterSalary",
                titleCharacters,
              })}
            />
          </div>
        {/if}
      </div>
      {#if filteredPostings?.length > 0}
        {#each filteredPostings as item}
          <div
            class="main-container"
            class:layout-mobile-width={atomWidthRem < 30}
            class:layout-tablet-width={atomWidthRem < 45}
            class:box-border={shouldAddBoxBorder}
          >
            <div class="overlay-area">
              <div class="top-row">
                <div class="header-area">
                  <div class="category-list">
                    <ButtonParticle
                      {..._.merge({}, topLeftButton, {
                        text: { text: item?.industry || "" },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div class="bottom-row">
                <div class="text-icon-area">
                  <div class="text-area">
                    <div class="title-text">
                      {#if !_.isNil(titleText)}
                        <div class="title-inner">
                          <TextParticle
                            _block={true}
                            {...titleText}
                            lineClamp={2}
                            text={item?.title || ""}
                          />
                        </div>
                      {/if}
                      <div class="top-right-area">
                        <ButtonParticle
                          {..._.merge({}, topRightButton, {
                            text: { text: item?.location || "" },
                          })}
                        />
                      </div>
                    </div>
                    <div class="description-text">
                      {#if !_.isNil(descriptionText)}
                        <RichTextParticle
                          _block={true}
                          {...descriptionText}
                          html={item?.description || ""}
                        />
                      {/if}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cta-row">
                {#if ctaButton?.text?.text}
                  <div class="cta-button">
                    <ButtonParticle
                      {...ctaButton}
                      link={resolveUrl(item?.link || "#").href || "#"}
                    />
                  </div>
                {/if}
              </div>
              <div class="footer-row">
                <div class="footer-row-text1">
                  <TextParticle {...footerText} text={item?.type} />
                </div>
                <div class="footer-row-text2">
                  <TextParticle {...footerText} text={item?.salary} />
                </div>
                <!-- <div class="footer-row-spacer"></div> -->
                <div class="footer-row-button">
                  <ButtonParticle
                    {..._.merge({}, footerButton, {
                      text: {
                        text: `${$t("Posted")} ${item?.livedate}` || "",
                      },
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        {/each}
      {:else if filteredPostings?.length < 1 && !isLoading}
        <div class="no-vacancy-box min-height-box">NO JOBS FOUND</div>
      {/if}
    </div>
  </div>
</BaseAtom>

<style>
  .outer-container {
    width: 100%;
  }

  .min-height-box {
    min-height: var(--boca-locked-height-px) !important;
  }

  .box-border {
    border: 2px solid var(--boca-box-border-color, #e9e9e9);
  }

  .left-panel {
    width: 0;
  }

  .right-panel {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 3rem;
    width: 100%;
  }

  .main-container {
    padding: var(--boca-item-box-padding, unset);

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    background-size: cover;
    background-position: top center;
    position: relative;
    height: 100%;
    width: 100%;

    background-color: white;
    border-radius: 1.5rem;
    overflow: hidden;
  }

  .main-container > .overlay-area {
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0rem;
    padding: 0;
  }

  .main-container > .overlay-area > .top-row {
    padding: var(--boca-overlay-padding, unset);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  .main-container > .overlay-area > .top-row > .header-area {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .main-container > .overlay-area > .top-row > .header-area > .category-list {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: start;
    gap: 1rem;
  }

  .main-container > .overlay-area > .bottom-row {
    padding: var(--boca-overlay-padding, unset);
    display: flex;
    justify-content: space-between;
  }

  .main-container > .overlay-area > .bottom-row > .text-icon-area > .text-area {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .main-container > .overlay-area > .cta-row {
    padding: var(--boca-overlay-padding, unset);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    transform: translateY(50%);
  }

  .main-container > .overlay-area > .cta-row > .cta-button {
    background-color: var(--cta-background-color);
    color: var(--cta-font-color);
    font-size: var(--cta-font-size, 1rem);
    font-weight: var(--cta-font-weight, 600);

    display: block;
    padding: 0rem 1rem;
    border-radius: 8px;
    white-space: nowrap;
  }

  .main-container.layout-mobile-width
    > .overlay-area
    > .top-row
    > .header-area {
    flex-direction: column;
    align-items: start;
    flex-wrap: wrap;
  }

  .text-icon-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .title-text {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid rgb(233, 233, 233);
    justify-content: start;
  }

  .description-text {
    width: 100%;
    max-height: 12rem;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
            mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
  }

  .main-container > .overlay-area > .footer-row {
    padding: var(--boca-overlay-padding, unset);
    padding-top: 1.5rem;
    padding-bottom: 1rem;

    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 0.75rem;
    background-color: var(--boca-footer-background-color, unset);
  }

  .main-container.layout-mobile-width .overlay-area .footer-row {
        gap: 0;
      }

  .main-container.layout-mobile-width .footer-row-button {
      margin: 1rem 0 0 1rem;
    }

  .main-container.layout-tablet-width .overlay-area .footer-row {
        gap: 0;
      }

  .main-container > .overlay-area > .footer-row > * {
    flex: 0 0 max-content;
  }

  .footer-row-button {
    margin-left: auto;
  }
  .main-container > .overlay-area > .footer-row > .footer-row-spacer {
    flex: 1 0 auto;
  }

  .filter-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    padding: 1rem;
  }
  .filter-container.layout-tablet-width,
  .filter-container.layout-mobile-width {
    display: grid;
    grid-template-columns: repeat(2, minmax(13rem, 1fr));
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
       -moz-column-gap: 0.5rem;
            column-gap: 0.5rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding: 1rem;
    align-content: center;
    justify-content: center;
    place-content: center;
  }
  .dropdown-item {
    width: 100%;
  }

  /* .top-right-area :global(svg) {
    width: 2.5rem;
    height: 2.5rem;
  } */

  .top-right-area :global(div) {
    flex: unset;
  }

  .no-vacancy-box {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: 2px solid #e9e9e9;
    background-color: #ffffff;
    border-radius: 1.5rem;
    font: bold 2rem "Fira Sans";
    color: #a6a6a6;
    height: 10rem;
  }</style>
