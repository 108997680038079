<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { t } from "js/i18n";
import "luxon";
import { formatDateTimeParts, } from "components/cards/utilities";
import { getI18nManager } from "components/cards/utilities/context";
import "components/cards/atoms";
import { BaseParticle, } from "components/cards/particles";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
const hovering = writable(false);
const formatDateTime = (dateString) => {
    return formatDateTimeParts(dateString, {
        role: 'fixtures',
        blankArrayItemsIfInvalid: true,
    }, {});
};
export let container;
export let overlayPadding;
export let backgroundColor;
export let imgLeaguelogo;
export let matchDateTime;
export let txtMatchDate;
export let txtMatchTime;
export let txtStadiumName;
export let imgBroadcastPartner;
export let txtHomeTeam;
export let txtAwayTeam;
export let imgHomeTeam;
export let imgAwayTeam;
export let btnActions;
export let matchStatus;
export let homeTeamScore;
export let awayTeamScore;
export let matchMinutes;
export let isChannelImageRequired;
export let isPenalty;
export let txtLive;
export let txtLiveSmall;
export let colorHeaderBackground;
export let colorLiveIconBorder;
export let txtStatus;
export let txtAwayTeamAbbr;
export let txtHomeTeamAbbr;
export let colorFirstButton;
export let colorLiveIconCircle;
export let isTicketSoldOut;
export let ticketSaleStartDate;
export let attendanceCount;
export let txtCenterBoxText;
export let lastButtonColor;
export let buyTicketColor;
export let defaultButtonColor;
export let bottomRowColor;
export let isAdditionalContentReqd;
export let lastButtonFontColor;
export let liveBackgroundColor;
export let homeTeamDarkLogoUrl;
export let awayTeamDarkLogoUrl;
export let shouldSwitchToLiveMode;
let atomWidthPx;
function handleImageError(event) {
    event.target.src =
        "https://resources-uk.yinzcam.com/soccer/shared/logos/_default.svg";
}
$: isMobile = atomWidthPx <= 750;
$: isTablet = atomWidthPx <= 1020 && atomWidthPx > 750;
$: leagueIconMultiplier = isMobile ? "0.71" : "1.00";
$: dateTextMultiplier = isMobile ? "0.66" : "1.00";
$: timeTextMultiplier = isMobile ? "0.56" : "1.00";
$: timeZoneTextMultiplier = isMobile ? "0.34" : "0.44";
$: stadiumNameTextMultiplier = isMobile ? "0.75" : "1.00";
$: teamTextMultiplier = isMobile ? "0.60" : isTablet ? "0.75" : "1.00";
$: timePeriodTextMultiplier = isMobile
    ? "0.60"
    : isTablet
        ? "0.40"
        : "1.00";
$: teamIconMultiplier = isMobile ? "0.60" : isTablet ? "0.80" : "1.00";
$: btnTextMultiplier = isMobile ? "0.70" : "1.00";
$: firstButtonProps = (btnActions === null || btnActions === void 0 ? void 0 : btnActions.length) && btnActions[0];
$: remainingButtons = (btnActions === null || btnActions === void 0 ? void 0 : btnActions.length) && (btnActions === null || btnActions === void 0 ? void 0 : btnActions.slice(1));
$: scoreTextMultiplier = isMobile ? "0.58" : "1.00";
$: matchMinsTextMultiplier = isMobile ? "0.56" : "1.00";
$: themeModeValue =
    matchStatus === "live" && shouldSwitchToLiveMode
        ? "inverted"
        : "primary";
$: txtPenaltiesMultiplier = isMobile ? "0.5" : "1.00";
$: ctaSizeMultiplier = isMobile ? "0.6" : "1.00";
$: headerText =
    matchStatus === "live"
        ? "LIVE"
        : matchStatus === "post"
            ? "RESULT"
            : "NEXT UP";
$: [day, date, month, year, , time, timeperiod, timezone] =
    formatDateTime(matchDateTime);
$: isThai = getI18nManager().getCurrentLanguage() === "th";
</script>

<BaseParticle
    _isotope="FixturesCardCParticle"
    {...merge({}, container, { themeMode: themeModeValue })}
    _cssVariablePrefix="fix-stack"
    {overlayPadding}
    {backgroundColor}
    {colorHeaderBackground}
    {colorLiveIconBorder}
    {colorFirstButton}
    {colorLiveIconCircle}
    {lastButtonColor}
    {buyTicketColor}
    {defaultButtonColor}
    {bottomRowColor}
    {lastButtonFontColor}
    {liveBackgroundColor}
    bind:_clientWidthPx={atomWidthPx}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
        class:live-match={matchStatus === "live" && shouldSwitchToLiveMode}
    >
        <div class="match-date">
            <TextParticle
                {...txtMatchDate}
                text={`${$t(day)} ${date} ${$t(month)}${CONFIG.dateHasComma && !isThai ? "," : ""} ${year}`}
                size={`calc(${get(txtMatchDate, "size")}*${dateTextMultiplier})`}
            />
        </div>
        <div
            class="match-center-grid"
            class:live={matchStatus === "live" && shouldSwitchToLiveMode}
        >
            <div class="left-content">
                {#if isMobile}
                    <TextParticle
                        {...txtHomeTeamAbbr}
                        text={txtHomeTeamAbbr?.text?.slice(0, 3)}
                        size={`calc(${get(txtHomeTeamAbbr, "size")}*${teamTextMultiplier})`}
                    />
                {:else}
                    <TextParticle
                        {...txtHomeTeam}
                        size={`calc(${get(txtHomeTeam, "size")}*${teamTextMultiplier})`}
                    />
                {/if}
                <ImageParticle
                    _block={true}
                    {...merge({}, imgHomeTeam, {
                        container: {
                            width: `calc(${get(imgHomeTeam, "container.width")}*${teamIconMultiplier})`,
                            height: `calc(${get(imgHomeTeam, "container.height")}*${teamIconMultiplier})`,
                        },
                        source:
                            matchStatus === "live" && shouldSwitchToLiveMode
                                ? homeTeamDarkLogoUrl
                                : imgHomeTeam?.source,
                    })}
                    {handleImageError}
                />
            </div>
            <div class="mid-content">
                <div class="center-content">
                    {#if matchStatus === "pre"}
                        <div class="pre-match-time">
                            <!-- <div class="match-date">
                                <TextParticle
                                    {...txtMatchDate}
                                    text={isMobile
                                        ? `${$t(day?.slice(0, 3))} ${date} ${$t(month?.slice(0, 3))}${CONFIG.dateHasComma ? "," : ""} ${year}`
                                        : `${$t(day)} ${date} ${$t(month)}, ${year}`}
                                    size="1.2rem"
                                />
                            </div> -->
                            <div class="match-time">
                                <TextParticle
                                    {...txtMatchTime}
                                    text={`${time}`}
                                    size={`calc(${get(txtMatchTime, "size")}*${teamTextMultiplier})`}
                                />
                                <TextParticle
                                    {...txtMatchTime}
                                    text={`${timeperiod}`}
                                    size={`calc(${"2rem"}*${timePeriodTextMultiplier})`}
                                />
                            </div>
                            <!-- <div class="timezone">
                                <TextParticle
                                    {...txtCenterBoxText}
                                    text={timezone}
                                />
                            </div> -->
                        </div>
                    {/if}
                    {#if matchStatus === "live"}
                        <div
                            class={`${shouldSwitchToLiveMode ? "live-match-score" : "post-match-score"}`}
                        >
                            <div class="home-score">
                                <TextParticle {...homeTeamScore} />
                            </div>
                            <div class="match-minutes">
                                <TextParticle {...matchMinutes} />
                            </div>
                            <div class="away-score">
                                <TextParticle {...awayTeamScore} />
                            </div>
                        </div>
                    {/if}
                    {#if matchStatus === "post"}
                        <div class="post-match-score">
                            <div class="home-score">
                                <TextParticle {...homeTeamScore} />
                            </div>
                            <div class="status-text">
                                <TextParticle {...txtStatus} />
                            </div>
                            <div class="away-score">
                                <TextParticle {...awayTeamScore} />
                            </div>
                        </div>
                    {/if}
                </div>
            </div>
            <div class="right-content">
                <ImageParticle
                    _block={true}
                    {...merge({}, imgAwayTeam, {
                        container: {
                            width: `calc(${get(imgAwayTeam, "container.width")}*${teamIconMultiplier})`,
                            height: `calc(${get(imgAwayTeam, "container.height")}*${teamIconMultiplier})`,
                        },
                        source:
                            matchStatus === "live" && shouldSwitchToLiveMode
                                ? awayTeamDarkLogoUrl
                                : imgAwayTeam?.source,
                    })}
                    {handleImageError}
                />
                {#if isMobile}
                    <TextParticle
                        {...txtAwayTeamAbbr}
                        text={txtAwayTeamAbbr?.text?.slice(0, 3)}
                        size={`calc(${get(txtAwayTeamAbbr, "size")}*${teamTextMultiplier})`}
                    />
                {:else}
                    <TextParticle
                        {...txtAwayTeam}
                        size={`calc(${get(txtAwayTeam, "size")}*${teamTextMultiplier})`}
                    />
                {/if}
            </div>
        </div>
    </div>
</BaseParticle>

<style>
    .main-container {
        width: 100%;
        height: 100%;
        padding: var(--fix-stack-overlay-padding);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* gap: 0.5rem; */
    }
    .main-container.live-match {
        background-color: var(--fix-stack-live-background-color, #164194);
    }
    .main-container.layout-mobile-width .match-center-grid {
            grid-template-columns: 1.5fr 3fr 1.5fr;
            gap: 0;
        }
    .main-container.layout-mobile-width .mid-content {
            gap: 0;
        }
    .main-container.layout-mobile-width .mid-content .center-content {
                width: 100%;
            }
    .main-container.layout-mobile-width .mid-content .center-content .pre-match-time {
                    padding: 0.5rem 0.75rem;
                    text-align: center;
                }
    .main-container.layout-mobile-width .mid-content .center-content .live-match-score,
                .main-container.layout-mobile-width .mid-content .center-content .post-match-score {
                    padding: 0.5rem 1rem;
                }
    .center-content {
        width: 100%;
    }
    .match-center-grid {
        display: grid;
        grid-template-columns: 4fr 4fr 4fr;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%;
        align-items: center;
    }
    .left-content {
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .left-content > :global(*) {
        text-align: right;
    }
    .right-content {
        display: flex;
        align-items: center;
        justify-content: left;
    }
    .right-content > :global(*) {
        text-align: left;
    }
    .mid-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
    }
    .pre-match-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--fix-stack-background-color, #cbcbcb);
        border-radius: 0.75rem;
        padding: 0.75rem 1.5rem;
    }
    .live-match-score {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
        background: var(--fix-stack-background-color, #fff);
        border-radius: 12px;
        padding: 0.75rem 1.5rem;
    }
    .post-match-score {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
        background: var(--fix-stack-background-color, #cbcbcb);
        border-radius: 12px;
        padding: 0.75rem 1.5rem;
    }</style>