<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { onMount } from "svelte";
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { BaseAtom } from "components/cards/atoms";
import { FixturesCardCParticle, } from "components/cards/particles/YinzCam";
export let container;
export let overlayPadding;
export let fixturesCardData = [];
export let cardsPerColumn;
let atomWidthRem;
let swiper;
let isNavigationVisible = true;
const handleNext = () => {
    swiper.slideNext();
};
const handlePrevious = () => {
    swiper.slidePrev();
};
const updateNavigationVisibility = () => {
    var _a, _b;
    if (swiper) {
        const totalSlides = (_a = swiper === null || swiper === void 0 ? void 0 : swiper.slides) === null || _a === void 0 ? void 0 : _a.length;
        const slidesPerView = (_b = swiper === null || swiper === void 0 ? void 0 : swiper.params) === null || _b === void 0 ? void 0 : _b.slidesPerView;
        isNavigationVisible = (swiper === null || swiper === void 0 ? void 0 : swiper.activeIndex) + slidesPerView >= totalSlides;
    }
};
function generateUid() {
    return "uid-" + Date.now() + "-" + Math.floor(Math.random() * 10000);
}
let uid = generateUid();
Swiper.use([Navigation, Pagination]);
onMount(() => {
    swiper = new Swiper(`.swiper${uid}`, {
        slidesPerView: "auto",
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        breakpointsBase: "container",
        breakpoints: {
            400: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            800: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1500: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });
});
let atomWidthPx;
const cardsPerColumnArray = [4, 5, 6];
$: isMobile = atomWidthPx <= 600;
$: isTablet = atomWidthPx <= 1024 && atomWidthPx > 600;
$: {
    const bestFit = cardsPerColumnArray.reduce((best, card) => {
        const remainder = (fixturesCardData === null || fixturesCardData === void 0 ? void 0 : fixturesCardData.length) % card;
        return remainder < best.remainder ? { card, remainder } : best;
    }, { card: cardsPerColumn || 0, remainder: Infinity });
    cardsPerColumn = bestFit.card;
}
</script>

<BaseAtom
    _isotope="SwiperFixturesCardCAtom"
    {...container}
    _cssVariablePrefix="swiper-fix"
    {overlayPadding}
    bind:_clientWidthPx={atomWidthPx}
>
    <div class="swiper-container" >
        <div class={`swiper${uid}`}>
            <div
                class="swiper-wrapper"
                class:mobile-layout={isMobile}
                class:tablet-layout={isTablet}
            >
                {#if fixturesCardData?.length > 0 && fixturesCardData?.[0]?.matchDateTime}
                    {#each Array(Math.ceil(fixturesCardData?.length / +cardsPerColumn)) as _, index}
                        <div class="swiper-slide" class:tablet-layout={isTablet}>
                            {#each fixturesCardData?.slice(index * +cardsPerColumn, index * +cardsPerColumn + +cardsPerColumn) as card}
                                <div class="fix-card">
                                    <FixturesCardCParticle {...card}/>
                                </div>
                            {/each}
                        </div>
                    {/each}
                {/if}
            </div>
            <div
                class="swiper-button-next"
                on:click={handleNext}
                class:hidden={!isNavigationVisible}
            ></div>
            <div
                class="swiper-button-prev"
                on:click={handlePrevious}
                class:hidden={!isNavigationVisible}
            ></div>
        </div>
    </div>
</BaseAtom>

<style>
  .swiper-container {
    width: 100%;
    /* padding: 0 40px; */
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #164194;
    font-weight: 800;
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

    .swiper-button-next{
        right: 0;
    }

  .swiper-button-prev {
    left: 0;
  }

  .fix-card{
    /* max-width: 400px; */
  }

  .swiper-slide.tablet-layout .fix-card{
        /* max-width: 600px; */
    }

    .swiper-button-next.hidden,
    .swiper-button-prev.hidden {
        display: none;
    }</style>
