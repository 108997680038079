<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import { get, isNil } from "lodash";
import { t } from "js/i18n";
import { formatDateTimeRelative, formatDuration, formatNumber, getPublishDuration, translateString, formatDateTimeParts, } from "components/cards/utilities";
import { BaseAtom, getContentManager, } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import "js/url";
import { DateTime } from "luxon";
const hovering = writable(false);
const formatDateTime = (dateString) => {
    return formatDateTimeParts(dateString, {
        role: 'media',
    }, Object.assign(Object.assign({}, DateTime.DATE_FULL), DateTime.TIME_24_SIMPLE));
};
export let container;
export let overlayPadding;
export let mediaType;
export let slug;
export let link;
export let publishDateTime;
export let viewTimeDuration;
export let views;
export let thumbnail;
export let titleText;
export let descriptionText;
export let txtDateTime;
export let imgClockIcon;
export let imgShareIcon;
export let shouldDisplayBodyText;
export let reverseOrientation;
export let displayDateDuration;
export let shouldDisplayTime;
export let shouldDisplayDuration;
export let shouldDisplayViews;
export let textBoxWidth;
export let categories;
export let shouldDisplayCategories;
export let totalPhotos;
export let imgCameraIcon;
export let shouldDisplayPhotosCount;
export let header;
export let photosCountPosition;
let atomWidthRem;
$: mtc = getContentManager().getMediaTypeConfigurationStore(mediaType, slug, link);
$: [firstPos, secondPos] = (photosCountPosition === null || photosCountPosition === void 0 ? void 0 : photosCountPosition.split("-")) || [
    "top",
    "right",
];
$: resolvedLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.linkUrl) === null || _a === void 0 ? void 0 : _a.href;
$: mediaTypeIcon = $mtc === null || $mtc === void 0 ? void 0 : $mtc.icon;
$: isTablet = atomWidthRem <= 60 && atomWidthRem > 30;
$: isMobile = atomWidthRem <= 30;
$: txtDurationValue = formatDateTimeRelative(publishDateTime);
$: viewDurationDisplay = translateString(formatDuration(viewTimeDuration), $t);
$: viewsDisplay = formatNumber(views);
$: titleTextMultiplier = isMobile ? "0.6" : isTablet ? "0.7" : "1.00";
$: textWidth = `${parseInt(textBoxWidth) < 100 ? textBoxWidth : 50}%`;
$: imageWidth = `${100 - parseInt(textWidth)}%`;
$: publishDateTimeDisplay = getPublishDuration(publishDateTime, $t);
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="RegularHorizontalAtom"
    _cssVariablePrefix="regular-horizontal"
    _link={resolvedLink}
    {...container}
    {overlayPadding}
    {textWidth}
    {imageWidth}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
        class:reverse={reverseOrientation === "text-first"}
    >
        <div class="image-container">
            <ImageParticle {...thumbnail} _block={true} />
            {#if shouldDisplayPhotosCount && +totalPhotos > 0}
                <div
                    class="photos-count"
                    style={`${firstPos}:1rem; ${secondPos}:1rem`}
                >
                    <ImageParticle {...imgCameraIcon} _block={true} />
                    <TextParticle {...header} text={`${totalPhotos}`} />
                </div>
            {/if}
        </div>
        <div class="text-container">
            <div class="top-row">
                <div class="categories-box">
                    {#if shouldDisplayCategories}
                        <div class="category-list">
                            {#each categories || [] as category}
                                <ButtonParticle {...category} />
                            {/each}
                        </div>
                    {/if}
                </div>

                <div class="title-description">
                    <TextParticle
                        {...titleText}
                        size={`calc(${get(titleText, "size")}*${titleTextMultiplier})`}
                    />
                    {#if shouldDisplayBodyText}
                        <TextParticle {...descriptionText} />
                    {/if}
                </div>
            </div>
            <div class="description-footer">
                <div class="footer-content">
                    <div class="time-content">
                        {#if shouldDisplayTime && !isNil(publishDateTime)}
                            <div class="clock-duration">
                                <div class="icon-particle">
                                    <ImageParticle
                                        {...imgClockIcon}
                                        _block={true}
                                    />
                                </div>
                                <div class="duration-particle">
                                    <TextParticle
                                        {...txtDateTime}
                                        text={publishDateTimeDisplay}
                                        _block={true}
                                    />
                                </div>
                                <!-- {#if shouldDisplayDuration && !isNil(viewTimeDuration)}
                                    <div>
                                        <span
                                            style="color: var(--theme-mode-foreground-color, #FFF); font-size: 1.25rem; font-weight: 500; line-height: 1rem"
                                            >|</span
                                        >
                                    </div>
                                {/if} -->
                            </div>
                        {/if}
                        {#if shouldDisplayDuration && !isNil(viewTimeDuration)}
                            <div class="reading-time-box">
                                <div class="icon-particle">
                                    <ImageParticle
                                        {...imgClockIcon}
                                        _block={true}
                                        icon="yc_icon_book"
                                    />
                                </div>
                                <div class="minute-read">
                                    <TextParticle
                                        {...txtDateTime}
                                        text=" {viewDurationDisplay || ''}"
                                        _block={true}
                                    />
                                </div>
                            </div>
                        {/if}
                        {#if shouldDisplayViews && !isNil(views)}
                            <div class="views-count">
                                <TextParticle
                                    {...txtDateTime}
                                    text="| {viewsDisplay || ''} {$t('views')}"
                                />
                            </div>
                        {/if}
                    </div>
                    <div class="share-icon">
                        <ImageParticle {...imgShareIcon} _block={true} />
                    </div>
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }
    .main-container.reverse {
        flex-direction: row-reverse;
    }

    .image-container {
        position: relative;
        flex: 0 0 var(--regular-horizontal-image-width);
    }

    .text-container {
        display: flex;
        flex: 0 1 var(--regular-horizontal-text-width);
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        padding: var(--regular-horizontal-overlay-padding, unset);
    }
    .title-description {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .footer-content {
        display: flex;
        /* justify-content: space-between; */
        /* align-items: center; */
        /* flex-basis: max-content; */
    }

    .clock-duration {
        display: flex;
        /* justify-content: end; */
        align-items: center;
        gap: 0.5rem;
    }
    .time-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        -webkit-column-gap: 0.75rem;
           -moz-column-gap: 0.75rem;
                column-gap: 0.75rem;
        row-gap: 0.25rem;
    }

    .reading-time-box {
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-column-gap: 0.5rem;
           -moz-column-gap: 0.5rem;
                column-gap: 0.5rem;
        row-gap: 0.25rem;
    }
    .share-icon {
        width: 10%;
    }
    .category-list {
        display: flex;
        flex-direction: row;
        align-items: start;
        -webkit-column-gap: 1rem;
           -moz-column-gap: 1rem;
                column-gap: 1rem;
        flex-wrap: wrap;
    }
    .top-row {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }
    .photos-count {
        position: absolute;
        /* top: 1rem;
        right: 1rem; */
        display: flex;
        align-items: center;
        background: var(--theme-mode-highlight-color);
        border-radius: 8px;
        padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    }</style>
