<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { t } from "js/i18n";
import "luxon";
import { formatDateTimeParts, } from "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
const hovering = writable(false);
const formatDateTime = (dateString) => {
    return formatDateTimeParts(dateString, {
        role: 'fixtures',
        blankArrayItemsIfInvalid: true,
    }, {});
};
/*
const formatDateTime = (dateString) => {
    const dt = DateTime.fromISO(dateString, {
        zone: "Europe/London",
        locale: "en-GB",
    });
    const convertedDateTime = dt
        .toLocaleString({
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            timeZoneName: "short",
        })
        ?.split(" ");
    if (convertedDateTime?.join("")?.includes("Invalid")) {
        return Array(8).fill("");
    }
    return convertedDateTime?.map((string) =>
        string.trim().replace(",", ""),
    );
};
*/
export let container;
export let overlayPadding;
export let backgroundColor;
export let imgLeaguelogo;
export let matchDateTime;
export let txtMatchDate;
export let txtMatchTime;
export let txtStadiumName;
export let imgBroadcastPartner;
export let txtHomeTeam;
export let txtAwayTeam;
export let imgHomeTeam;
export let imgAwayTeam;
export let btnActions;
export let matchStatus;
export let homeTeamScore;
export let awayTeamScore;
export let matchMinutes;
export let isChannelImageRequired;
export let isPenalty;
export let txtLive;
export let txtLiveSmall;
export let colorHeaderBackground;
export let colorLiveIconBorder;
export let txtStatus;
export let txtAwayTeamAbbr;
export let txtHomeTeamAbbr;
export let colorFirstButton;
export let colorLiveIconCircle;
export let isTicketSoldOut;
export let ticketSaleStartDate;
export let attendanceCount;
export let txtCenterBoxText;
export let lastButtonColor;
export let buyTicketColor;
export let defaultButtonColor;
export let bottomRowColor;
export let isAdditionalContentReqd;
export let lastButtonFontColor;
export let defaultButtonFontColor;
export let shouldDisplayBorder;
export let boxBorderColor;
export let liveBottomRowColor;
export let liveColorHeaderBackground;
export let liveHeaderFontColor;
export let liveBoxBorderColor;
export let liveBackgroundColor;
let atomWidthRem;
$: isMobile = atomWidthRem <= 40;
$: isTablet = atomWidthRem <= 64 && atomWidthRem > 40;
$: leagueIconMultiplier = isMobile ? "0.65" : "1.00";
$: themeModeValue = matchStatus === "live" ? "inverted" : "primary";
$: headerText =
    matchStatus === "live"
        ? "LIVE"
        : matchStatus === "post"
            ? "RESULT"
            : "NEXT UP";
$: [day, date, month, year, , time, timeperiod, timezone] =
    formatDateTime(matchDateTime);
$: colorHeaderBackground =
    matchStatus === "live"
        ? liveColorHeaderBackground
        : colorHeaderBackground;
$: bottomRowColor =
    matchStatus === "live" ? liveBottomRowColor : bottomRowColor;
$: backgroundColor =
    matchStatus === "live" ? liveBackgroundColor : backgroundColor;
$: boxBorderColor =
    matchStatus === "live" ? liveBoxBorderColor : boxBorderColor;
</script>

<BaseAtom
    _isotope="FixturesCardBAtom"
    {...merge({}, container, { themeMode: themeModeValue })}
    _cssVariablePrefix="fix-stack"
    {overlayPadding}
    {backgroundColor}
    {colorHeaderBackground}
    {colorLiveIconBorder}
    {colorFirstButton}
    {colorLiveIconCircle}
    {lastButtonColor}
    {buyTicketColor}
    {defaultButtonColor}
    {bottomRowColor}
    {lastButtonFontColor}
    {defaultButtonFontColor}
    {boxBorderColor}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
        class:live-match={matchStatus === "live"}
    >
        <div class="match-center-grid" class:live={matchStatus === "live"}>
            <div class="top-row">
                <div class="league-icon">
                    <ImageParticle
                        {...merge({}, imgLeaguelogo, {
                            container: {
                                height: `calc(${get(imgLeaguelogo, "container.height")} * ${leagueIconMultiplier})`,
                                width: `calc(${get(imgLeaguelogo, "container.width")} * ${leagueIconMultiplier})`,
                            },
                        })}
                        _block={true}
                    />
                </div>
                <div class="date-venue">
                    <div class="match-date">
                        <TextParticle
                            {...txtMatchDate}
                            text={isMobile
                                ? `${$t(day?.slice(0, 3))} ${date} ${$t(month?.slice(0, 3))}`
                                : `${$t(day)} ${date} ${$t(month)}`}
                            color={matchStatus === "live"
                                ? liveHeaderFontColor
                                : txtMatchDate?.color}
                        />
                    </div>
                    <div class="match-venue">
                        <TextParticle
                            {...txtStadiumName}
                            _block={true}
                            color={matchStatus === "live"
                                ? liveHeaderFontColor
                                : txtStadiumName?.color}
                        />
                    </div>
                </div>
                <div class="status-specific-content">
                    {#if matchStatus === "pre" && isAdditionalContentReqd}
                        <div class="top-row-pre-content">
                            <div class="text-content">
                                <TextParticle
                                    {...ticketSaleStartDate}
                                    text={`Tickets ${isTicketSoldOut ? "" : "on sale from"}`}
                                    weight="400"
                                />
                                <TextParticle
                                    {...ticketSaleStartDate}
                                    text={`${isTicketSoldOut ? "SOLD" : `${time?.slice(0, 5)} ${day?.slice(0, 3)} ${date} ${month?.slice(0, 3)}`}`}
                                />
                            </div>
                            <div class="icon-content">
                                <ImageParticle
                                    icon="yc_icon_ticket"
                                    container={{
                                        width: "2.5rem",
                                        height: "2.5rem",
                                    }}
                                />
                            </div>
                        </div>
                    {/if}
                    {#if matchStatus === "live"}
                        <div class="top-row-live-content">
                            <div class="icon-content">
                                <svg
                                    width={`24px`}
                                    height={`24px`}
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="16"
                                        cy="16"
                                        r="11"
                                        stroke="red"
                                        stroke-width="2"
                                    />
                                    <circle
                                        cx="16"
                                        cy="16"
                                        r="6.66667"
                                        fill="red"
                                    />
                                </svg>
                            </div>
                            <div class="text-content">
                                <TextParticle {...txtLive} text="LIVE" />
                            </div>
                        </div>
                    {/if}
                    {#if matchStatus === "post" && isAdditionalContentReqd}
                        <div class="top-row-post-content">
                            <div class="text-content">
                                <TextParticle
                                    {...ticketSaleStartDate}
                                    text={"Attendance"}
                                    weight="400"
                                />
                            </div>
                            <div class="icon-content">
                                <TextParticle
                                    {...ticketSaleStartDate}
                                    text={"32845"}
                                />
                            </div>
                        </div>
                    {/if}
                </div>
            </div>
            <div
                class="bottom-row"
                class:bordered-box={shouldDisplayBorder &&
                    matchStatus !== "live"}
            >
                <div class="left-content"></div>
                <div class="mid-content">
                    <div class="home-logo">
                        <ImageParticle {...imgHomeTeam} />
                    </div>
                    <div class="center-content">
                        {#if matchStatus === "pre"}
                            <div class="pre-match-time">
                                <div class="match-time">
                                    <TextParticle
                                        {...txtMatchTime}
                                        text={`${time?.slice(0, 5)} ${$t(timeperiod)}`}
                                    />
                                </div>
                                <!-- <div class="timezone">
                                    <TextParticle
                                        {...txtCenterBoxText}
                                        text={timezone}
                                    />
                                </div> -->
                            </div>
                        {/if}
                        {#if matchStatus === "live"}
                            <div class="live-match-score">
                                <div class="home-score">
                                    <TextParticle {...homeTeamScore} />
                                </div>
                                <div class="match-minutes">
                                    <TextParticle
                                        {...matchMinutes}
                                        color="#7C7C7C"
                                    />
                                </div>
                                <div class="away-score">
                                    <TextParticle {...awayTeamScore} />
                                </div>
                            </div>
                        {/if}
                        {#if matchStatus === "post"}
                            <div class="post-match-score">
                                <div class="home-score">
                                    <TextParticle {...homeTeamScore} />
                                </div>
                                <div class="status-text">
                                    <TextParticle {...txtStatus} />
                                </div>
                                <div class="away-score">
                                    <TextParticle {...awayTeamScore} />
                                </div>
                            </div>
                        {/if}
                    </div>
                    <div class="away-logo">
                        <ImageParticle {...imgAwayTeam} />
                    </div>
                </div>
                <div class="right-content"></div>
            </div>
        </div>
        <div class="button-grid">
            {#each btnActions || [] as button, index}
                {#if button.link}
                    <div class="cta-button">
                        <ButtonParticle
                            {...button}
                            {...merge({}, button, {
                                container: {
                                    background: {
                                        color:
                                            btnActions?.length > 1 &&
                                            index === btnActions?.length - 1
                                                ? "var(--fix-stack-last-button-color, #EAEAEA)"
                                                : button?.text?.text?.toLowerCase() ===
                                                    "buy tickets"
                                                  ? "var(--fix-stack-buy-ticket-color, #164194)"
                                                  : "var(--fix-stack-default-button-color, #011858)",
                                    },
                                },
                                image: {
                                    source: "",/*
                                        btnActions?.length > 1 &&
                                        index === btnActions?.length - 1
                                            ? "https://resources-uk.yinzcam.com/soccer/shared/icon_foxes_hub.png"
                                            : "",*/
                                },
                                text: {
                                    color:
                                        btnActions?.length > 1 &&
                                        index === btnActions?.length - 1
                                            ? "var(--fix-stack-last-button-font-color, #000)"
                                            : "var(--fix-stack-default-button-font-color, #011858)",
                                },
                                showText: true,/*
                                    btnActions?.length > 1 &&
                                    index === btnActions?.length - 1 &&
                                    isMobile
                                        ? false
                                        : true,*/
                            })}
                        />
                    </div>
                {/if}
            {/each}
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        padding: var(--fix-stack-overlay-padding);
        display: grid;
        grid-template-columns: 9fr 3fr;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .bordered-box {
        border: 2px solid var(--fix-stack-box-border-color, #eaeaea);
        border-top: none;
    }
    .match-center-grid {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        justify-content: space-between;
        place-content: space-between;
    }
    .top-row {
        display: grid;
        grid-template-columns: 2.5fr 7fr 2.5fr;
        align-items: center;
        justify-items: center;
        place-items: center;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        padding: 0 1rem;
        background-color: var(
            --fix-stack-color-header-background,
            var(--theme-mode-background-color, #eaeaea)
        );
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .league-icon {
        justify-self: flex-start;
    }
    .bottom-row {
        display: grid;
        grid-template-columns: 2.5fr 7fr 2.5fr;
        align-items: center;
        justify-items: center;
        place-items: center;
        padding: 1rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        background-color: var(--fix-stack-bottom-row-color, #fff);
    }
    .bottom-row .mid-content {
            display: flex;
        }
    .bottom-row .mid-content .center-content {
                width: 12rem;
                border-radius: 0.75rem;
                background-color: var(--fix-stack-background-color, #cbcbcb);
                padding: 0.75rem 1.25rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    .date-venue {
        text-align: center;
    }
    .status-specific-content {
        text-align: right;
        justify-self: flex-end;
    }
    .top-row-pre-content {
        display: flex;
    }
    .top-row-pre-content .text-content {
            display: flex;
            flex-direction: column;
        }
    .top-row-post-content {
        display: flex;
        flex-direction: column;
    }
    .top-row-live-content {
        display: flex;
        align-items: center;
    }
    .pre-match-time {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .live-match-score,
    .post-match-score {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .match-center-grid.live {
        color: var(--theme-mode-foreground-color, #fff);
    }
    .match-center-grid.live .top-row {
            background-color: var(
                --fix-stack-color-header-background,
                var(--theme-mode-background-color, #011858)
            );
        }
    .match-center-grid.live .bottom-row {
            background-color: var(
                --fix-stack-bottom-row-color,
                var(--theme-mode-background-color, #164194)
            );
        }
    .cta-button {
        /* padding: 1rem; */
    }
    .button-grid {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: space-evenly;
    }
    .main-container.layout-mobile-width {
        display: flex;
        flex-direction: column;
    }
    .main-container.layout-mobile-width .button-grid {
            width: 100%;
            flex-direction: row;
            justify-content: center;
        }</style>
